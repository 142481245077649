interface IgnoreErrors {
    [key: string]: string[]
}

const ignoreErrors: IgnoreErrors = {
    '/ct_api/member': ['Request failed with status code 404'],
    '/ct_api/event/concert': ['Request failed with status code 400']
}

export default ignoreErrors
