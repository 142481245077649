import { useMutation } from '@tanstack/vue-query'

import { request } from '@/server/core/request'

export const MUTATE_KEY = 'MUTATE_INSURANCE_PDF'

interface InvalidData {
    hasNoInsNoData: boolean
}
interface PostInsurancePDFResponse {
    products: any[]
    insurances: any[]
    invalidData: InvalidData
    errors: any[]
}

async function postInsurance(data: FormData) {
    return new Promise<PostInsurancePDFResponse>((resolve, reject) => {
        request('POST', `/api/pdf`, {
            data,
            contentType: 'multipart/form-data'
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export default function mutationInsurancePDF() {
    return useMutation({
        mutationKey: [MUTATE_KEY],
        mutationFn: (payload: any) => postInsurance(payload)
    })
}
